import { Injectable } from '@angular/core';

import { ErrorHandlingType } from '../../enums/error-handling-type.enum';
import { EnrichedError } from '../../models/enriched-error.model';
import { SvgErrorMessageFactoryService } from '../error-message-factory/svg-error-message-factory.service';

@Injectable({ providedIn: 'root' })
export class EnrichedErrorFactoryService {
	constructor(private errorMessageFactoryService: SvgErrorMessageFactoryService) {}

	by(error: Error, handlingType: ErrorHandlingType, reportSentry: boolean): EnrichedError {
		const displayMessages = this.errorMessageFactoryService.getMessageForError(error);
		return { displayMessages, error, handlingType, reportSentry };
	}

	byKey(errorKey: string, handlingType: ErrorHandlingType, reportSentry: boolean): EnrichedError {
		const displayMessages: string[] = [this.errorMessageFactoryService.getMessageForKey(errorKey)];
		return { displayMessages, error: new Error(displayMessages.join(' ')), handlingType, reportSentry };
	}
}
