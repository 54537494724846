import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { EmptyRouterOutletComponent } from './components/empty-router-outlet/empty-router-outlet.component';
import { BreakpointsDirective } from './directives/breakpoints.directive';
import { LetDirective } from './directives/let.directive';
import { absPipe } from './pipes/abs.pipe';
import { AccountConditionUnitPipe } from './pipes/account-condition-unit.pipe';
import { ArrayContainsOneOfPipe } from './pipes/array-contains-one-of.pipe';
import { ArrayContainsPipe } from './pipes/array-contains.pipe';
import { ArrayJoinPipe } from './pipes/array-join.pipe';
import { AsiTimeElapsedFromNowPipe } from './pipes/asi-time-elapsed-from-now/asi-time-elapsed-from-now.pipe';
import { BackendDateFormatPipe } from './pipes/backend-date-format.pipe';
import { BoolPipe } from './pipes/bool.pipe';
import { BooleanYesNoPipe } from './pipes/boolean-yes-no/boolean-yes-no.pipe';
import { CentsToEuroPipe } from './pipes/cents-to-euro.pipe';
import { DateInPastPipe } from './pipes/date-in-past/date-in-past.pipe';
import { DateRestrictionPipe } from './pipes/date-restriction/date-restriction.pipe';
import { DurationInHoursPipe } from './pipes/duration-in-hours/duration-in-hours.pipe';
import { EnumPipe } from './pipes/enum.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { HtmlStringNormalizationPipe } from './pipes/html-string-normalization/html-string-normalization.pipe';
import { IbanPipe } from './pipes/iban.pipe';
import { IsDateInPastPipe } from './pipes/is-date-in-past.pipe';
import { IsDefinedPipe } from './pipes/is-defined/is-defined.pipe';
import { IsNotDefinedHandlingPipe } from './pipes/is-not-defined-handling/is-not-defined-handling.pipe';
import { ListMappedPropertyToStringPipe } from './pipes/list-property/list-mapped-property-to-string.pipe';
import { NumberToRomanNumeral } from './pipes/number-to-roman-numeral.pipe';
import { PropertyPipe } from './pipes/property/property.pipe';
import { SecurityExpertNamePipe } from './pipes/security-expert-name/security-expert-name.pipe';
import { SortStringPropertyPipe } from './pipes/sort-string-property.pipe';
import { StatePipe } from './pipes/state.pipe';
import { TimeElapsedFromNowPipe } from './pipes/time-elapsed-from-now/time-elapsed-from-now.pipe';
import { TrackingNumberToTrackingUrlPipe } from './pipes/tracking-number-to-tracking-url.pipe';
import { WithLoadingPipe } from './pipes/with-loading.pipe';

const DECLARABLES = [
	// Pipes
	absPipe,
	AsiTimeElapsedFromNowPipe,
	ArrayJoinPipe,
	BooleanYesNoPipe,
	BackendDateFormatPipe,
	DateRestrictionPipe,
	IsDefinedPipe,
	TimeElapsedFromNowPipe,
	AccountConditionUnitPipe,
	ArrayContainsOneOfPipe,
	ArrayContainsPipe,
	BoolPipe,
	CentsToEuroPipe,
	DateInPastPipe,
	DurationInHoursPipe,
	EnumPipe,
	FilterArrayPipe,
	HtmlStringNormalizationPipe,
	ListMappedPropertyToStringPipe,
	NumberToRomanNumeral,
	IbanPipe,
	IsDateInPastPipe,
	IsNotDefinedHandlingPipe,
	PropertyPipe,
	SecurityExpertNamePipe,
	SortStringPropertyPipe,
	StatePipe,
	TimeElapsedFromNowPipe,
	TrackingNumberToTrackingUrlPipe,
	WithLoadingPipe,
	// Directives
	LetDirective,
	BreakpointsDirective,
	// Components
	EmptyRouterOutletComponent,
];

@NgModule({
	declarations: DECLARABLES,
	exports: [...DECLARABLES],
	imports: [RouterModule],
})
export class UtilsModule {}
