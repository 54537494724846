/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteInvoiceSettings } from '../fn/tc-invoice-settings/delete-invoice-settings';
import { DeleteInvoiceSettings$Params } from '../fn/tc-invoice-settings/delete-invoice-settings';
import { getInvoiceSettings } from '../fn/tc-invoice-settings/get-invoice-settings';
import { GetInvoiceSettings$Params } from '../fn/tc-invoice-settings/get-invoice-settings';
import { patchInvoiceSettingsInvoiceToCustomer } from '../fn/tc-invoice-settings/patch-invoice-settings-invoice-to-customer';
import { PatchInvoiceSettingsInvoiceToCustomer$Params } from '../fn/tc-invoice-settings/patch-invoice-settings-invoice-to-customer';
import { saveInvoiceSettingsInvoiceToCustomer } from '../fn/tc-invoice-settings/save-invoice-settings-invoice-to-customer';
import { SaveInvoiceSettingsInvoiceToCustomer$Params } from '../fn/tc-invoice-settings/save-invoice-settings-invoice-to-customer';
import { saveInvoiceSettingsInvoiceToSvg } from '../fn/tc-invoice-settings/save-invoice-settings-invoice-to-svg';
import { SaveInvoiceSettingsInvoiceToSvg$Params } from '../fn/tc-invoice-settings/save-invoice-settings-invoice-to-svg';
import { TcInvoiceSettingsDto } from '../models/tc-invoice-settings-dto';

@Injectable({ providedIn: 'root' })
export class TcInvoiceSettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getInvoiceSettings()` */
  static readonly GetInvoiceSettingsPath = '/svgs/{svgId}/customers/{customerId}/invoice-settings';

  /**
   * Get TCInvoiceSettings of a customer. This resource only exists once per customer.
   *
   * TCInvoiceSettings can be of two types. Either to be used by Command Agent to structure and deliver invoice or just displayed for customer - and Command Agent will send the invoice to svg.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvoiceSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoiceSettings$Response(params: GetInvoiceSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceSettingsDto>> {
    return getInvoiceSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Get TCInvoiceSettings of a customer. This resource only exists once per customer.
   *
   * TCInvoiceSettings can be of two types. Either to be used by Command Agent to structure and deliver invoice or just displayed for customer - and Command Agent will send the invoice to svg.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvoiceSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoiceSettings(params: GetInvoiceSettings$Params, context?: HttpContext): Observable<TcInvoiceSettingsDto> {
    return this.getInvoiceSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceSettingsDto>): TcInvoiceSettingsDto => r.body)
    );
  }

  /** Path part for operation `deleteInvoiceSettings()` */
  static readonly DeleteInvoiceSettingsPath = '/svgs/{svgId}/customers/{customerId}/invoice-settings';

  /**
   * Delete TCInvoiceSettings of a customer.
   *
   * Delete TCInvoiceSettings of a customer. For example if this data is no longer needed, cause this customer does not exist anymore.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInvoiceSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInvoiceSettings$Response(params: DeleteInvoiceSettings$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteInvoiceSettings(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete TCInvoiceSettings of a customer.
   *
   * Delete TCInvoiceSettings of a customer. For example if this data is no longer needed, cause this customer does not exist anymore.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteInvoiceSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInvoiceSettings(params: DeleteInvoiceSettings$Params, context?: HttpContext): Observable<void> {
    return this.deleteInvoiceSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `patchInvoiceSettingsInvoiceToCustomer()` */
  static readonly PatchInvoiceSettingsInvoiceToCustomerPath = '/svgs/{svgId}/customers/{customerId}/invoice-settings';

  /**
   * Patch endpoint to edit InvoiceSettings but not change the type.
   *
   * Patch endpoint to edit InvoiceSettings but not change the type. This endpoint is for customer users, put endpoints are able to change the type, which a customer should not do as of now.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchInvoiceSettingsInvoiceToCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchInvoiceSettingsInvoiceToCustomer$Response(params: PatchInvoiceSettingsInvoiceToCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceSettingsDto>> {
    return patchInvoiceSettingsInvoiceToCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Patch endpoint to edit InvoiceSettings but not change the type.
   *
   * Patch endpoint to edit InvoiceSettings but not change the type. This endpoint is for customer users, put endpoints are able to change the type, which a customer should not do as of now.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchInvoiceSettingsInvoiceToCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchInvoiceSettingsInvoiceToCustomer(params: PatchInvoiceSettingsInvoiceToCustomer$Params, context?: HttpContext): Observable<TcInvoiceSettingsDto> {
    return this.patchInvoiceSettingsInvoiceToCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceSettingsDto>): TcInvoiceSettingsDto => r.body)
    );
  }

  /** Path part for operation `saveInvoiceSettingsInvoiceToCustomer()` */
  static readonly SaveInvoiceSettingsInvoiceToCustomerPath = '/svgs/{svgId}/customers/{customerId}/invoice-settings/invoice-to-customer';

  /**
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_CUSTOMER`. This will overwrite an existing resource with type `INVOICE_TO_SVG`.
   *
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_CUSTOMER`, which is used by Command Agent to structure and deliver the invoice.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveInvoiceSettingsInvoiceToCustomer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveInvoiceSettingsInvoiceToCustomer$Response(params: SaveInvoiceSettingsInvoiceToCustomer$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceSettingsDto>> {
    return saveInvoiceSettingsInvoiceToCustomer(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_CUSTOMER`. This will overwrite an existing resource with type `INVOICE_TO_SVG`.
   *
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_CUSTOMER`, which is used by Command Agent to structure and deliver the invoice.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveInvoiceSettingsInvoiceToCustomer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveInvoiceSettingsInvoiceToCustomer(params: SaveInvoiceSettingsInvoiceToCustomer$Params, context?: HttpContext): Observable<TcInvoiceSettingsDto> {
    return this.saveInvoiceSettingsInvoiceToCustomer$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceSettingsDto>): TcInvoiceSettingsDto => r.body)
    );
  }

  /** Path part for operation `saveInvoiceSettingsInvoiceToSvg()` */
  static readonly SaveInvoiceSettingsInvoiceToSvgPath = '/svgs/{svgId}/customers/{customerId}/invoice-settings/invoice-to-svg';

  /**
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_SVG`. This will overwrite an existing resource with type `INVOICE_TO_CUSTOMER`.
   *
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_SVG`, which is only displayed for customer, Command Agent will send invoice to svg - not customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveInvoiceSettingsInvoiceToSvg()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveInvoiceSettingsInvoiceToSvg$Response(params: SaveInvoiceSettingsInvoiceToSvg$Params, context?: HttpContext): Observable<StrictHttpResponse<TcInvoiceSettingsDto>> {
    return saveInvoiceSettingsInvoiceToSvg(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_SVG`. This will overwrite an existing resource with type `INVOICE_TO_CUSTOMER`.
   *
   * Creates or updates TCInvoiceSettings with type `INVOICE_TO_SVG`, which is only displayed for customer, Command Agent will send invoice to svg - not customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `saveInvoiceSettingsInvoiceToSvg$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveInvoiceSettingsInvoiceToSvg(params: SaveInvoiceSettingsInvoiceToSvg$Params, context?: HttpContext): Observable<TcInvoiceSettingsDto> {
    return this.saveInvoiceSettingsInvoiceToSvg$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcInvoiceSettingsDto>): TcInvoiceSettingsDto => r.body)
    );
  }

}
