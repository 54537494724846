import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Get currency values as cents (an integer input number value e.g. 1350) divide by 100 to get floating point value (13,50) and return as euro currency (13,50€)
 */
@Pipe({ name: 'centsToEuro' })
export class CentsToEuroPipe implements PipeTransform {
	constructor(private currencyPipe: CurrencyPipe) {}

	transform(value: number | null): string {
		if (value === null || isNaN(value)) {
			return '';
		}
		return this.currencyPipe.transform(value / 100, 'EUR');
	}
}
