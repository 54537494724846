import { Injectable } from '@angular/core';
import {
	ClarityIcons,
	angleIcon,
	bellIcon,
	calendarIcon,
	checkIcon,
	cogIcon,
	exclamationCircleIcon,
	exclamationTriangleIcon,
	floppyIcon,
	folderIcon,
	loginIcon,
	playIcon,
	plusIcon,
	popOutIcon,
	refreshIcon,
	tagsIcon,
	trashIcon,
	undoIcon,
	windowCloseIcon,
	zoomInIcon,
	zoomOutIcon,
} from '@cds/core/icon';
import { IconShapeTuple } from '@cds/core/icon/interfaces/icon.interfaces';

export const ICONS: IconShapeTuple[] = [
	angleIcon,
	bellIcon,
	calendarIcon,
	checkIcon,
	cogIcon,
	exclamationCircleIcon,
	exclamationTriangleIcon,
	floppyIcon,
	folderIcon,
	loginIcon,
	playIcon,
	plusIcon,
	popOutIcon,
	refreshIcon,
	tagsIcon,
	trashIcon,
	undoIcon,
	windowCloseIcon,
	zoomInIcon,
	zoomOutIcon,
];

@Injectable({ providedIn: 'root' })
export class ClarityInitializationService {
	init(): void {
		ClarityIcons.addIcons(...this.getIcons());
	}

	protected getIcons(): IconShapeTuple[] {
		return ICONS;
	}
}
