/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BlobCoordinates } from '../models/blob-coordinates';
import { uploadToBlob } from '../fn/upload/upload-to-blob';
import { UploadToBlob$Params } from '../fn/upload/upload-to-blob';

@Injectable({ providedIn: 'root' })
export class UploadService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `uploadToBlob()` */
  static readonly UploadToBlobPath = '/svgs/{svgId}/customers/{customerId}/upload-to-blob';

  /**
   * Uploads a file.
   *
   * Upload a file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadToBlob()` instead.
   *
   * This method sends `application/octet-stream` and handles request body of type `application/octet-stream`.
   */
  uploadToBlob$Response(params: UploadToBlob$Params, context?: HttpContext): Observable<StrictHttpResponse<BlobCoordinates>> {
    return uploadToBlob(this.http, this.rootUrl, params, context);
  }

  /**
   * Uploads a file.
   *
   * Upload a file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadToBlob$Response()` instead.
   *
   * This method sends `application/octet-stream` and handles request body of type `application/octet-stream`.
   */
  uploadToBlob(params: UploadToBlob$Params, context?: HttpContext): Observable<BlobCoordinates> {
    return this.uploadToBlob$Response(params, context).pipe(
      map((r: StrictHttpResponse<BlobCoordinates>): BlobCoordinates => r.body)
    );
  }

}
