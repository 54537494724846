/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createCustomerDocument } from '../fn/tc-documents/create-customer-document';
import { CreateCustomerDocument$Params } from '../fn/tc-documents/create-customer-document';
import { createTemporaryCustomerDocumentUrl } from '../fn/tc-documents/create-temporary-customer-document-url';
import { CreateTemporaryCustomerDocumentUrl$Params } from '../fn/tc-documents/create-temporary-customer-document-url';
import { getAllCustomerDocumentCategories } from '../fn/tc-documents/get-all-customer-document-categories';
import { GetAllCustomerDocumentCategories$Params } from '../fn/tc-documents/get-all-customer-document-categories';
import { getCustomerDocuments } from '../fn/tc-documents/get-customer-documents';
import { GetCustomerDocuments$Params } from '../fn/tc-documents/get-customer-documents';
import { patchCustomerDocument } from '../fn/tc-documents/patch-customer-document';
import { PatchCustomerDocument$Params } from '../fn/tc-documents/patch-customer-document';
import { TcDocumentCategory } from '../models/tc-document-category';
import { TcDocumentDownloadDto } from '../models/tc-document-download-dto';
import { TcDocumentDto } from '../models/tc-document-dto';
import { TcDocumentPaginatedBody } from '../models/tc-document-paginated-body';

@Injectable({ providedIn: 'root' })
export class TcDocumentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCustomerDocuments()` */
  static readonly GetCustomerDocumentsPath = '/svgs/{svgId}/customers/{customerId}/documents';

  /**
   * Get all document meta data resources for a given customer.
   *
   * Retrieves all filtered and paginated document meta data for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDocuments$Response(params: GetCustomerDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDocumentPaginatedBody>> {
    return getCustomerDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all document meta data resources for a given customer.
   *
   * Retrieves all filtered and paginated document meta data for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDocuments(params: GetCustomerDocuments$Params, context?: HttpContext): Observable<TcDocumentPaginatedBody> {
    return this.getCustomerDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcDocumentPaginatedBody>): TcDocumentPaginatedBody => r.body)
    );
  }

  /** Path part for operation `createCustomerDocument()` */
  static readonly CreateCustomerDocumentPath = '/svgs/{svgId}/customers/{customerId}/documents';

  /**
   * Create a document metadata resource.
   *
   * Creates a document metadata object for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCustomerDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerDocument$Response(params: CreateCustomerDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDocumentDto>> {
    return createCustomerDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a document metadata resource.
   *
   * Creates a document metadata object for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCustomerDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCustomerDocument(params: CreateCustomerDocument$Params, context?: HttpContext): Observable<TcDocumentDto> {
    return this.createCustomerDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcDocumentDto>): TcDocumentDto => r.body)
    );
  }

  /** Path part for operation `getAllCustomerDocumentCategories()` */
  static readonly GetAllCustomerDocumentCategoriesPath = '/svgs/{svgId}/customers/{customerId}/documents/categories';

  /**
   * Returns all supported document categories.
   *
   * Returns all supported document categories.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCustomerDocumentCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerDocumentCategories$Response(params?: GetAllCustomerDocumentCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TcDocumentCategory>>> {
    return getAllCustomerDocumentCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all supported document categories.
   *
   * Returns all supported document categories.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCustomerDocumentCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomerDocumentCategories(params?: GetAllCustomerDocumentCategories$Params, context?: HttpContext): Observable<Array<TcDocumentCategory>> {
    return this.getAllCustomerDocumentCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TcDocumentCategory>>): Array<TcDocumentCategory> => r.body)
    );
  }

  /** Path part for operation `patchCustomerDocument()` */
  static readonly PatchCustomerDocumentPath = '/svgs/{svgId}/customers/{customerId}/documents/{tcDocumentId}';

  /**
   * Change the visibility of a document metadata.
   *
   * Changes the visibility of a document metadata object for a given customer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCustomerDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerDocument$Response(params: PatchCustomerDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDocumentDto>> {
    return patchCustomerDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Change the visibility of a document metadata.
   *
   * Changes the visibility of a document metadata object for a given customer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCustomerDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchCustomerDocument(params: PatchCustomerDocument$Params, context?: HttpContext): Observable<TcDocumentDto> {
    return this.patchCustomerDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcDocumentDto>): TcDocumentDto => r.body)
    );
  }

  /** Path part for operation `createTemporaryCustomerDocumentUrl()` */
  static readonly CreateTemporaryCustomerDocumentUrlPath = '/svgs/{svgId}/customers/{customerId}/documents/{tcDocumentId}/download';

  /**
   * Create a download url for a document.
   *
   * Create a download url by attaching a sas token to the given blob url. The generated Url is not persisted. The sas token has an expiration, so this url is only temporary usable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemporaryCustomerDocumentUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTemporaryCustomerDocumentUrl$Response(params: CreateTemporaryCustomerDocumentUrl$Params, context?: HttpContext): Observable<StrictHttpResponse<TcDocumentDownloadDto>> {
    return createTemporaryCustomerDocumentUrl(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a download url for a document.
   *
   * Create a download url by attaching a sas token to the given blob url. The generated Url is not persisted. The sas token has an expiration, so this url is only temporary usable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTemporaryCustomerDocumentUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createTemporaryCustomerDocumentUrl(params: CreateTemporaryCustomerDocumentUrl$Params, context?: HttpContext): Observable<TcDocumentDownloadDto> {
    return this.createTemporaryCustomerDocumentUrl$Response(params, context).pipe(
      map((r: StrictHttpResponse<TcDocumentDownloadDto>): TcDocumentDownloadDto => r.body)
    );
  }

}
